:root {
  --primary-main: #0066ff;
  --primary-light: #408CFF;
  --primary-dark: #0c387a;
}

/* Styles for okta login widget */
#okta-sign-in {
  width: 500px;
}

#okta-sign-in.auth-container h2 {
  font-size: x-large;
  color: var(--primary-dark);
}

#okta-sign-in.auth-container.main-container {
  border-color: var(--primary-main);
  border-width: 2px;
  border-radius: 2rem;
  margin-top: 50px;
}

#okta-sign-in .auth-org-logo {
  max-height: 100px;
}

#okta-sign-in .o-form .input-fix {
  height: 50px;
  border-radius: 1rem;
  border-width: 1px;
  border-color: var(--primary-main);
}

#okta-sign-in input[type="text"]:focus {
  box-shadow: 0 0 20px var(--primary-main);
  border-radius: 1rem;
}

#okta-sign-in input[type="password"]:focus {
  box-shadow: 0 0 20px var(--primary-main);
  border-radius: 1rem;
}

/* okta sign in button color */
#okta-sign-in.auth-container .button-primary {
  background: var(--primary-main);
}

#okta-sign-in.auth-container .button-primary:hover {
  background: var(--primary-light);
}

/* okta sign in button shape */
#okta-sign-in .button {
  border-width: 0px;
  border-radius: 3rem;
  max-height: 60px;
  min-height: 60px;
  text-transform: uppercase;
}

#okta-sign-in .auth-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

#okta-sign-in.no-beacon .auth-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border-bottom-color: var(--primary-main);
}
